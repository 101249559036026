import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslate } from '@sprinx/react-globalize';
import { shoppingCustomerSelector } from '../../../api/shopping';
import CustomerProfilePageContactCard from './CustomerProfilePageContactCard';

export type CustomerProfilePageCompanyPreviewProps = StandardDivProps<CustomerProfilePageCompanyPreviewClassKey>;

export type CustomerProfilePageCompanyPreviewClassKey = 'root' | 'title' | 'feedLink';

const themeSettings = { name: 'CustomerProfilePageCompanyPreview' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CustomerProfilePageCompanyPreviewClassKey, {}>({
      root: { display: 'flex', flexDirection: 'column', flexGrow: 1 },
      title: {
        ...theme.typography.h6,
        fontWeight: 600,
      },
      feedLink: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: theme.palette.primary.main,
      },
    }),
  themeSettings,
);

const CustomerProfilePageCompanyPreview = React.forwardRef<HTMLDivElement, CustomerProfilePageCompanyPreviewProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const customer = useRecoilValue(shoppingCustomerSelector);
    const address = ((customer?.postaddresses || []).find((i: any) => i.type === 'HQ') || {}).place;
    const t = useTranslate();

    if (!customer) {
      return <div>{t('customerProfilePage/errorLoading')}</div>;
    }

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        {customer && (
          <CustomerProfilePageContactCard title={t('common/company')}>
            <span className={classes.title}>{customer.name}</span>
            <span>{address?.full}</span>
            {customer?.type === 'company' && (
              <>
                <span>{`${t('common/regNo')}: ${customer?.regNo || '?'} ${t('common/taxNo')} ${
                  customer?.vatNo || '?'
                }`}</span>
                <span>{`${t('common/currency')}: ${customer?.currency || '?'}`}</span>
                <span>{`${t('common/paymentType')}: ${customer?.extra?.paymentType || '?'}`}</span>
                <span>{`${t('common/maturityPeriod')}: ${customer?.extra?.maturityPeriod || '?'} ${t(
                  'common/days',
                )}`}</span>
                <span>{`${t('common/priceLevel')}: ${customer?.extra?.priceLevelName || '?'}`}</span>
                <span>{`${t('common/region')}: ${customer?.extra?.customerDetailedRegion || '?'}`}</span>
                <span>{`${t('common/responsiblePersonName')}: ${customer?.extra?.responsiblePersonName || '?'}`}</span>
                {customer?.extra?.xmlFeedHash && (
                  <a
                    className={classes.feedLink}
                    href={`/feed/${customer?.extra?.xmlFeedHash}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    XML feed
                  </a>
                )}
              </>
            )}
          </CustomerProfilePageContactCard>
        )}
      </div>
    );
  },
);

CustomerProfilePageCompanyPreview.displayName = 'CustomerProfilePageCompanyPreview';

export default withThemeProps(themeSettings)(CustomerProfilePageCompanyPreview);
