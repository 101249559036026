import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import useRouteUrlFactory from '../../../hooks/useRouteUrlFactory';

export interface AppFooterProps {
  handleDrawerToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: '40px',
      backgroundColor: '#002969',
      color: 'white',
      display: 'flex',
      padding: theme.spacing(2),
      '& ul': {
        listStyle: 'none',
        paddingLeft: 0,
      },
      '@media (max-width: 1100px)': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    navLink: {
      color: 'white',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    logoHolder: {
      minWidth: '280px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      '& img': {
        maxHeight: '50px',
      },
    },
    lists: {
      display: 'flex',
      gap: '40px',
      width: '100%',
      justifyContent: 'space-evenly',
      '& h3': {
        marginTop: 0,
      },
      '@media (max-width: 490px)': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    copyright: {
      fontSize: '12px',
    },
    contactBox: {
      display: 'flex',
      flexDirection: 'row',
      gap: '20px',
      minWidth: '380px',
      '& h3': {
        marginTop: 0,
      },
      '& a': {
        color: 'white',
        breakWord: 'nobreak',
      },
      '@media (max-width: 490px)': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  }),
  { name: 'AppFooter' },
);

export default function AppFooter(): JSX.Element {
  const classes = useStyles();
  const t = useTranslate();
  const routeUrlFactory = useRouteUrlFactory();
  const currentYear = new Date().getFullYear();

  return (
    <div className={classes.root}>
      <div className={classes.logoHolder}>
        <img src='/logoPht_small.png' alt='' />
        <p className={classes.copyright}>
          © {currentYear} {t('mainNavigation/allRightsReserved')}
        </p>
      </div>
      <div className={classes.lists}>
        <div>
          <h3>{t('mainNavigation/aboutUs')}</h3>
          <ul>
            <li>
              <Link
                component={RouterLink}
                to={routeUrlFactory('homePage')}
                color='textPrimary'
                variant='inherit'
                className={classes.navLink}
                noWrap
              >
                {t('mainNavigation/introduction')}
              </Link>
            </li>
            <li>
              <Link
                component={RouterLink}
                to={routeUrlFactory('contacts')}
                color='textPrimary'
                variant='inherit'
                className={classes.navLink}
                noWrap
              >
                {t('mainNavigation/contacts')}
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h3>{t('mainNavigation/allAboutShopping')}</h3>
          <ul>
            <li>
              <Link
                component={RouterLink}
                to={routeUrlFactory('catalogue')}
                color='textPrimary'
                variant='inherit'
                className={classes.navLink}
                noWrap
              >
                {t('mainNavigation/products')}
              </Link>
            </li>
            <li>
              <a
                href='https://stary.magg.cz/katalognaradi'
                target='_blank'
                rel='noreferrer'
                className={classes.navLink}
              >
                {t('mainNavigation/ecatalogue')}
              </a>
            </li>
            <li>
              <Link
                component={RouterLink}
                to={routeUrlFactory('gdprPage')}
                color='textPrimary'
                variant='inherit'
                className={classes.navLink}
                noWrap
              >
                {t('mainNavigation/gdpr')}
              </Link>
            </li>
            <li>
              <Link
                component={RouterLink}
                to={routeUrlFactory('termsAndConditions')}
                color='textPrimary'
                variant='inherit'
                className={classes.navLink}
                noWrap
              >
                {t('mainNavigation/tos')}
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h3>{t('mainNavigation/headquarters')}</h3>
          <p>
            Na stráži 1410/11
            <br />
            180 00 Praha 8 - Libeň
            <br />
            IČO: 260 56 577
            <br />
            +420 387 316 285
            <br />
            info@pht.cz
          </p>
        </div>
      </div>
      <div className={classes.contactBox}>
        <h3>{t('mainNavigation/orderNow')}</h3>
        <a href='tel:+420 387 316 285'>+420 387 316 285</a>
        <a href='mailto:info@pht.cz'>info@pht.cz</a>
      </div>
    </div>
  );
}
