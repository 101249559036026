import FormikExtended from '@sprinx/react-forms/FormikExtended';
import WrapDialogForm from '@sprinx/react-mui-components/WrapDialogForm';
import PriceField from '@sprinx/react-mui-fields/PriceField';
import TextField from '@sprinx/react-mui-fields/TextField';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslate } from '@sprinx/react-globalize';
import { apiClientState, currencyState } from '../../../api/appState';
import { enqueueSnackbarMessage } from '../../../api/snackbars';
import CustomerProfilePageDeliveryPlaceSelector from './CustomerProfilePageDeliveryPlaceSelector';
import CustomerProfilePageUserSupervisorSelector from './CustomerProfilePageUserSupervisorSelector';
import { SidiaryUser } from './useSubsidiaryUsersList';

export interface CustomerProfilePageUserEditDialogProps {
  customerId: string;
  isMaster?: boolean;
  onClose: () => void;
  onFormReleased: (p: any) => any;
  onSaved: () => void;
  open: boolean;
  userDetails: SidiaryUser;
}

export interface CustomerProfileEdit {
  deliveryPlaces: Array<string> | undefined;
  name: string;
  // email: string;
  orderTotalLimit: number | undefined;
  supervisor: string | undefined;
  userId: string;
}
export interface CustomerProfileEditErrors {
  // email?: string;
  name?: string;
  orderTotalLimit?: string;
  supervisor?: string;
}

export interface CustomerProfileEditTouched {
  // email?: boolean;
  name?: boolean;
  orderTotalLimit?: boolean;
  supervisor?: boolean;
}

function useUserSetDetailsHandler(): (params: CustomerProfileEdit) => Promise<void> {
  const apiClient = useRecoilValue(apiClientState);
  return React.useCallback(
    (params: CustomerProfileEdit) => apiClient.post<void, CustomerProfileEdit>('/v1/users/update-subsidiary', params),
    [apiClient],
  );
}

const CustomerProfilePageUserEditDialog: React.FC<CustomerProfilePageUserEditDialogProps> = ({
  customerId,
  isMaster,
  userDetails,
  open,
  onClose,
  onFormReleased,
  onSaved: handleSaved,
}) => {
  const t = useTranslate();
  const currency = useRecoilValue(currencyState);
  const setUserDetails = useUserSetDetailsHandler();
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);
  const handleSave = React.useCallback(
    async ({ userId, name, supervisor, orderTotalLimit, deliveryPlaces }: CustomerProfileEdit) => {
      if (userId && name) {
        setUserDetails({ userId, name, supervisor, orderTotalLimit, deliveryPlaces })
          .then(() => {
            handleSaved();
            showMessage({ message: t('customerProfilePage/changeData/messages/success'), variant: 'success' });
          })
          .catch(() => {
            showMessage({ message: t('customerProfilePage/changeData/messages/error'), variant: 'error' });
          });
      }
    },
    [setUserDetails, showMessage, handleSaved],
  );

  return (
    <FormikExtended<CustomerProfileEdit, CustomerProfileEditErrors, CustomerProfileEditTouched>
      onFormReleased={onFormReleased}
      onSave={handleSave}
      initialValues={{
        userId: userDetails.id,
        name: userDetails.name,
        supervisor: userDetails.supervisor,
        orderTotalLimit: userDetails.orderTotalLimit,
        deliveryPlaces: userDetails.deliveryPlaces || [customerId],
        // email: userDetails.email,
      }}
      validationFastest
      validationSchema={{
        userId: { type: 'string', empty: false },
        // email: { type: 'email', empty: false },
        name: { type: 'string', empty: false },
      }}
      render={(formikProps) => (
        <WrapDialogForm
          {...formikProps}
          dialogTitle={t('customerProfilePage/changeData/dialogTitle')}
          open={open}
          onClose={onClose}
          dialogProps={{
            fullWidth: true,
            maxWidth: 'sm',
          }}
          render={() => {
            const { errors, onChange: handleChange, values } = formikProps;
            return (
              <>
                <TextField
                  label={t('common/name')}
                  disabled={isMaster}
                  helperText={''}
                  value={values.name}
                  error={errors.name}
                  onChange={handleChange('name')}
                  fullWidth
                />
                <PriceField
                  currency={currency}
                  label={t('orders/order/priceLimit')}
                  helperText={''}
                  value={values.orderTotalLimit}
                  error={errors.orderTotalLimit}
                  onChange={handleChange('orderTotalLimit')}
                  fullWidth
                />
                <CustomerProfilePageUserSupervisorSelector
                  fullWidth
                  label={t('common/approver')}
                  customerId={customerId}
                  userId={userDetails.id}
                  value={values.supervisor}
                  onChange={handleChange('supervisor')}
                />
                {!isMaster && (
                  <CustomerProfilePageDeliveryPlaceSelector
                    value={values.deliveryPlaces || []}
                    onChange={handleChange('deliveryPlaces')}
                  />
                )}
                {/* <TextField
                  label={'E-mail'}
                  helperText={''}
                  value={values.email}
                  error={errors.email}
                  onChange={handleChange('email')}
                  fullWidth
                /> */}
              </>
            );
          }}
        />
      )}
    />
  );
};

export default CustomerProfilePageUserEditDialog;
