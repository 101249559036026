import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslate } from '@sprinx/react-globalize';
import { shoppingCustomerDeliveryPlacesAllSelector } from '../../../api/shopping';

export type CustomerProfilePageDeliveryPlaceSelectorProps = {
  onChange: any;
  value: Array<string>;
};

export type CustomerProfilePageDeliveryPlaceSelectorClassKey = 'root' | 'label';

const useStyles = makeStyles((theme) =>
  createStyles<CustomerProfilePageDeliveryPlaceSelectorClassKey, {}>({
    root: {
      maxHeight: '400px',
      overflow: 'scroll',
      overflowX: 'hidden',
    },
    label: {
      marginTop: theme.spacing(2),
      fontWeight: 'bold',
    },
  }),
);

const CustomerProfilePageDeliveryPlaceSelector: React.FC<CustomerProfilePageDeliveryPlaceSelectorProps> = ({
  // userId,
  value,
  // customerId,
  onChange,
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const places: Array<any> = useRecoilValue(shoppingCustomerDeliveryPlacesAllSelector) || [];

  return (
    <div className={classes.root}>
      <div className={classes.label}>{t('customerProfilePage/deliveryPlaces/label')}</div>
      <List>
        {places?.map((item, index: number) => {
          const address =
            (item.postaddresses || []).find((address: Record<string, any>) => address.type === 'HQ')?.place.full ||
            null;
          return (
            <ListItem key={index}>
              <Checkbox
                checked={!!value.find((x) => x === item.id)}
                onChange={(e, b) => onChange(b ? [...value, item.id] : value.filter((id) => id !== item.id))}
              />
              <Typography className=''>{`${item.officeName || item.name}${
                address != null ? ` - ${address}` : ''
              }`}</Typography>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default CustomerProfilePageDeliveryPlaceSelector;
