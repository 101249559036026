import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import React from 'react';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import PasswordField from '@sprinx/react-mui-fields/PasswordField';
import TextField from '@sprinx/react-mui-fields/TextField';
import LockIcon from '@material-ui/icons/LockTwoTone';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslate } from '@sprinx/react-globalize';
import IconButton from '@material-ui/core/IconButton';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Helmet } from 'react-helmet';
import { isAuthenticatedState } from '../../api/appState';
import { AfterPageProps, wrapLayout } from '../../@sprinx/react-after-razzle';
import DialogConfirm from '../../@sprinx/react-mui-components/DialogConfirm';
import { useApiClient } from '../../api/appState';
import useLoginHandler from '../../api/auth/useLoginHandler';
import { customerNotificationOpenState } from '../../api/customerNotification';
import { enqueueSnackbarMessage } from '../../api/snackbars';
import useRouteUrlFactory from '../../hooks/useRouteUrlFactory';
import LoginRegistrationDialog from '../LoginPage/components/LoginRegistrationDialog';
import InfoLayout from '../../layouts/InfoLayout/InfoLayout';
import { localeState } from '../../api/appState';
import type { SupportedLocale } from '../../i18n/types';
import type { RouteUrlType } from '../../routesBuilder';
import SimpleImage from './components/SimpleImage';

export type HomePageNewProps = AfterPageProps;
export type HomePageNewClassNameKey =
  | 'root'
  | 'content'
  // | 'headline'
  | 'fields'
  | 'actions'
  // | 'logo'
  | 'loginButton'
  | 'forgotFieldsContainer'
  | 'registrationButton'
  | 'carousel'
  | 'sideLoginPanel'
  | 'hpContent'
  | 'infoBox'
  | 'infoBoxHeading'
  | 'infoBoxContent'
  | 'infoBoxContentSection'
  | 'carouselWrapper'
  | 'imgSection'
  | 'mainWrapper'
  | 'backToImg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<HomePageNewClassNameKey, {}>({
    root: {
      paddingTop: theme.spacing(15),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
      },
      '@media (max-width: 1100px)': {
        paddingTop: '80px',
      },
    },
    imgSection: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '20px',
      '& img': {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
        maxWidth: '100%',
      },
    },
    content: {},
    sideLoginPanel: {
      minWidth: '300px',
      maxWidth: '350px',
      padding: theme.spacing(2, 3),
      paddingLeft: '0',
      borderRight: '1px solid #e0e0e0',
      marginRight: '10px',
      '@media (max-width: 1100px)': {
        display: 'flex',
        minWidth: 'unset',
        maxWidth: 'unset',
        flexDirection: 'column',
        borderRight: 'none',
        borderBottom: '1px solid #e0e0e0',
        marginRight: '0',
        paddingRight: '0',
      },
    },
    backToImg: {
      '& img': {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
        maxWidth: '100%',
      },
    },
    hpContent: {
      paddingTop: '40px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '40px',
      maxWidth: '900px',
      width: '100%',
    },
    mainWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: '20px',
      padding: '0 20px',
      background: 'white',
      border: '1px solid #e0e0e0',
      '@media (max-width: 1100px)': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    infoBox: {
      border: '1px solid #e0e0e0',
      borderRadius: '5px',
      marginBottom: '20px',
      padding: '10px',
    },
    infoBoxHeading: {
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#002969',
      background: '#C6C6C6',
      borderTop: `2px solid #002969`,
      borderBottom: `2px solid #002969`,
      padding: '5px',
      marginTop: 0,
    },
    infoBoxContent: {
      paddingLeft: '10px',
    },
    carouselWrapper: {
      aspectRatio: '16/10',
      minHeight: '200px',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: '100% !important',
        height: 'auto !important',
        objectFit: 'contain',
        maxWidth: '100%',
      },
    },
    fields: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '20px',
      '& > h3': {
        color: '#002969',
        textTransform: 'uppercase',
        marginBottom: 0,
      },
      '& > div': {
        margin: 0,
      },
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
      '& > a': {
        cursor: 'pointer',
        marginRight: 'auto',
      },
    },
    forgotFieldsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    loginButton: {
      backgroundColor: '#002969',
      color: 'white',
      '&:hover': {
        backgroundColor: '#003485',
      },
    },
    registrationButton: {
      backgroundColor: theme.palette.appBar.background,
      '&:hover': {
        backgroundColor: lighten(theme.palette.appBar.background, 0.2),
      },
    },
    carousel: {
      '& a': {
        display: 'inline-block',
      },
    },
    infoBoxContentSection: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2, 0),
    },
  }),
);

const initialValues = {
  username: '',
  password: '',
  forgotUser: '',
};

interface Values {
  forgotUser: string;
  password: string;
  username: string;
}

type StringRecord = Record<SupportedLocale, string> & { default: string };

const carouselItems: Array<{
  alt: StringRecord;
  hrefProps?: { options: { params: { [key: string]: string } }; type: RouteUrlType };
  src: StringRecord;
  title: StringRecord;
}> = [
  {
    src: {
      en: '/Banner_-_SOLA_-_EN.jpg',
      cs: '/Banner_-_SOLA_-_CZ.jpg',
      default: '/Banner_-_SOLA_-_EN.jpg',
    },
    alt: {
      en: 'Banner - SOLA',
      cs: 'Banner - SOLA',
      default: 'Banner - SOLA',
    },
    title: {
      en: 'Banner - SOLA',
      cs: 'Banner - SOLA',
      default: 'Banner - SOLA',
    },
  },
  {
    src: {
      en: '/Banner_-_WERA_-_EN.jpg',
      cs: '/Banner_-_WERA_-_CZ.jpg',
      default: '/Banner_-_WERA_-_EN.jpg',
    },
    alt: {
      en: 'Banner - WERA',
      cs: 'Banner - WERA',
      default: 'Banner - WERA',
    },
    title: {
      en: 'Banner - WERA',
      cs: 'Banner - WERA',
      default: 'Banner - WERA',
    },
  },
  {
    src: {
      en: '/Banner_130068_EN.jpg',
      cs: '/Banner_130068.jpg',
      default: '/Banner_130068_EN.jpg',
    },
    alt: {
      en: '130068 EN',
      cs: '130068',
      default: '130068 EN',
    },
    title: {
      en: '130068 EN',
      cs: '130068',
      default: '130068 EN',
    },
    hrefProps: {
      type: 'product',
      options: {
        params: { sn: 'tuson-akumulatorovy-sroubovak-3-6-v', id: '6634e15f41ac4d001cc22eeb' },
      },
    },
  },
  {
    src: {
      en: '/Banner_POLY03_EN.jpg',
      cs: '/Banner_POLY03.jpg',
      default: '/Banner_POLY03_EN.jpg',
    },
    alt: {
      en: 'Banner POLY03 EN',
      cs: 'Banner POLY03',
      default: 'Banner POLY03 EN',
    },
    title: {
      en: 'Banner POLY03 EN',
      cs: 'Banner POLY03',
      default: 'Banner POLY03 EN',
    },
    hrefProps: {
      type: 'product',
      options: {
        params: { sn: 'tuson-polyfuzni-svarecka-120-w-aku-18-v-makita-bez-baterie', id: '6634e15f41ac4d001cc22eec' },
      },
    },
  },
  {
    src: {
      en: '/Banner_Kemping_EN.jpg',
      cs: '/Banner_Kemping.jpg',
      default: '/Banner_Kemping_EN.jpg',
    },
    alt: {
      en: 'Banner kemping',
      cs: 'Banner kemping',
      default: 'Banner kemping',
    },
    title: {
      en: 'Banner kemping',
      cs: 'Banner kemping',
      default: 'Banner kemping',
    },
    hrefProps: {
      type: 'categories',
      options: { params: { categories: 'akce-kemping' } },
    },
  },
  {
    src: {
      en: '/kleste-na-plastove-trubky-do-prumeru-64-mm_EN.webp',
      cs: '/kleste-na-plastove-trubky-do-prumeru-64-mm.webp',
      default: '/kleste-na-plastove-trubky-do-prumeru-64-mm_EN.webp',
    },
    alt: {
      en: 'PLIERS FOR PLASTIC PIPES UP TO 64MM',
      cs: 'KLEŠTĚ NA PLASTOVÉ TRUBKY DO PRŮMĚRU 64mm',
      default: 'PLIERS FOR PLASTIC PIPES UP TO 64MM',
    },
    title: {
      en: 'PLIERS FOR PLASTIC PIPES UP TO 64MM',
      cs: 'KLEŠTĚ NA PLASTOVÉ TRUBKY DO PRŮMĚRU 64mm',
      default: 'PLIERS FOR PLASTIC PIPES UP TO 64MM',
    },
    hrefProps: {
      type: 'product',
      options: {
        params: { sn: 'kleste-na-plastove-trubky-do-prumeru-64-mm', id: '660c10f641ac4d001cc22e8b' },
      },
    },
  },
  {
    src: {
      en: '/sada-bitu-s-racnou-1-4-45-ks_EN.webp',
      cs: '/sada-bitu-s-racnou-1-4-45-ks.webp',
      default: '/sada-bitu-s-racnou-1-4-45-ks_EN.webp',
    },
    alt: {
      en: 'RATCHET BITS SET 1-4 - 45 PCS',
      cs: 'SADA BITŮ S RÁČNOU 1-4 - 45 KS',
      default: 'RATCHET BITS SET 1-4 - 45 PCS',
    },
    title: {
      en: 'RATCHET BITS SET 1-4 - 45 PCS',
      cs: 'SADA BITŮ S RÁČNOU 1-4 - 45 KS',
      default: 'RATCHET BITS SET 1-4 - 45 PCS',
    },
    hrefProps: {
      type: 'product',
      options: {
        params: { sn: 'sada-bitu-s-racnou-1-4-45-ks', id: '6613897241ac4d001cc22e8f' },
      },
    },
  },
  {
    src: {
      en: '/sada-klicu-ockoplochych-8-ks_EN.webp',
      cs: '/sada-klicu-ockoplochych-8-ks.webp',
      default: '/sada-klicu-ockoplochych-8-ks_EN.webp',
    },
    alt: {
      en: 'SET OF FLAT WRENCHES - 8 PCS',
      cs: 'SADA KLÍČŮ OČKOPLOCHÝCH - 8 KS',
      default: 'SET OF FLAT WRENCHES - 8 PCS',
    },
    title: {
      en: 'SET OF FLAT WRENCHES - 8 PCS',
      cs: 'SADA KLÍČŮ OČKOPLOCHÝCH - 8 KS',
      default: 'SET OF FLAT WRENCHES - 8 PCS',
    },
    hrefProps: {
      type: 'product',
      options: {
        params: { sn: 'sada-klicu-ockoplochych-8-ks', id: '6613897241ac4d001cc22e90' },
      },
    },
  },
  {
    src: {
      en: '/sada-sterek-na-silikony-2-ks_EN.webp',
      cs: '/sada-sterek-na-silikony-2-ks.webp',
      default: '/sada-sterek-na-silikony-2-ks_EN.webp',
    },
    alt: {
      en: 'SET OF SILICONE SPATUMS - 2 PCS',
      cs: 'SADA STĚREK NA SILIKONY - 2 KS',
      default: 'SET OF SILICONE SPATUMS - 2 PCS',
    },
    title: {
      en: 'SET OF SILICONE SPATUMS - 2 PCS',
      cs: 'SADA STĚREK NA SILIKONY - 2 KS',
      default: 'SET OF SILICONE SPATUMS - 2 PCS',
    },
    hrefProps: {
      type: 'product',
      options: {
        params: { sn: 'sada-sterek-na-silikony-2-ks', id: '66227a7441ac4d001cc22ebb' },
      },
    },
  },
  {
    src: {
      en: '/skrinky-plastove_EN.webp',
      cs: '/skrinky-plastove.webp',
      default: '/skrinky-plastove_EN.webp',
    },
    alt: {
      en: 'PLASTIC CABINETS',
      cs: 'SKŘÍŇKY PLASTOVÉ',
      default: 'PLASTIC CABINETS',
    },
    title: {
      en: 'PLASTIC CABINETS',
      cs: 'SKŘÍŇKY PLASTOVÉ',
      default: 'PLASTIC CABINETS',
    },
    hrefProps: {
      type: 'categories',
      options: { params: { categories: 'novinky' } },
    },
  },
  {
    src: {
      en: '/sverky-pakove_EN.webp',
      cs: '/sverky-pakove.webp',
      default: '/sverky-pakove_EN.webp',
    },
    alt: {
      en: 'LEVER CLAMPS',
      cs: 'SVĚRKY PÁKOVÉ',
      default: 'LEVER CLAMPS',
    },
    title: {
      en: 'LEVER CLAMPS',
      cs: 'SVĚRKY PÁKOVÉ',
      default: 'LEVER CLAMPS',
    },
    hrefProps: {
      type: 'categories',
      options: { params: { categories: 'novinky' } },
    },
  },
  {
    src: {
      en: '/sverky-sroubove_EN.webp',
      cs: '/sverky-sroubove.webp',
      default: '/sverky-sroubove_EN.webp',
    },
    alt: {
      en: 'SCREW CLAMPS',
      cs: 'SVĚRKY ŠROUBOVÉ',
      default: 'SCREW CLAMPS',
    },
    title: {
      en: 'SCREW CLAMPS',
      cs: 'SVĚRKY ŠROUBOVÉ',
      default: 'SCREW CLAMPS',
    },
    hrefProps: {
      type: 'categories',
      options: { params: { categories: 'novinky' } },
    },
  },
  {
    src: {
      en: '/sverky-sroubove-na-trubky_EN.webp',
      cs: '/sverky-sroubove-na-trubky.webp',
      default: '/sverky-sroubove-na-trubky_EN.webp',
    },
    alt: {
      en: 'SCREW CLAMPS FOR PIPES',
      cs: 'SVĚRKY ŠROUBOVÉ NA TRUBKY',
      default: 'SCREW CLAMPS FOR PIPES',
    },
    title: {
      en: 'SCREW CLAMPS FOR PIPES',
      cs: 'SVĚRKY ŠROUBOVÉ NA TRUBKY',
      default: 'SCREW CLAMPS FOR PIPES',
    },
    hrefProps: {
      type: 'categories',
      options: { params: { categories: 'novinky' } },
    },
  },
  {
    src: {
      en: '/svinovaci-metry-3-5-7-5m_EN.webp',
      cs: '/svinovaci-metry-3-5-7-5m.webp',
      default: '/svinovaci-metry-3-5-7-5m_EN.webp',
    },
    alt: {
      en: 'WINDING METERS 3 - 5 - 7,5 m',
      cs: 'SVINOVACÍ METRY 3 - 5 - 7,5 m',
      default: 'WINDING METERS 3 - 5 - 7,5 m',
    },
    title: {
      en: 'WINDING METERS 3 - 5 - 7,5 m',
      cs: 'SVINOVACÍ METRY 3 - 5 - 7,5 m',
      default: 'WINDING METERS 3 - 5 - 7,5 m',
    },
    hrefProps: {
      type: 'categories',
      options: { params: { categories: 'novinky' } },
    },
  },
  {
    src: {
      en: '/skrabka-na-silikon_EN.webp',
      cs: '/skrabka-na-silikon.webp',
      default: '/skrabka-na-silikon_EN.webp',
    },
    alt: {
      en: 'SILICONE SCRAPER',
      cs: 'ŠKRABKA NA SILIKON',
      default: 'SILICONE SCRAPER',
    },
    title: {
      en: 'SILICONE SCRAPER',
      cs: 'ŠKRABKA NA SILIKON',
      default: 'SILICONE SCRAPER',
    },
    hrefProps: {
      type: 'product',
      options: {
        params: { sn: 'skrabka-na-silikon', id: '66227a7441ac4d001cc22ebd' },
      },
    },
  },
  {
    src: {
      en: '/tesarky-uhelnik-7_EN.webp',
      cs: '/tesarky-uhelnik-7.webp',
      default: '/tesarky-uhelnik-7_EN.webp',
    },
    alt: {
      en: 'CARPENTERS ANGLE 7',
      cs: 'TESAŘSKÝ ÚHELNÍK 7',
      default: 'CARPENTERS ANGLE 7',
    },
    title: {
      en: 'CARPENTERS ANGLE 7',
      cs: 'TESAŘSKÝ ÚHELNÍK 7',
      default: 'CARPENTERS ANGLE 7',
    },
    hrefProps: {
      type: 'product',
      options: {
        params: { sn: 'tesarsky-uhelnik-7', id: '66227a7441ac4d001cc22ebc' },
      },
    },
  },
  {
    src: {
      en: '/Banner-Bessey_nabidka_EN.jpg',
      cs: '/Banner-Bessey_nabidka.jpg',
      default: '/Banner-Bessey_nabidka_EN.jpg',
    },
    alt: {
      en: 'Banner - Special offer bessey',
      cs: 'Banner - akcni nabidka bessey',
      default: 'Banner - Special offer bessey',
    },
    title: {
      en: 'Banner - Special offer bessey',
      cs: 'Banner - akcni nabidka bessey',
      default: 'Banner - Special offer bessey',
    },
    hrefProps: {
      type: 'categories',
      options: { params: { categories: 'akce-bessey' } },
    },
  },
];

const HomePageNew: React.FC<HomePageNewProps> = ({ history }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<Values>(initialValues);
  const [error, setError] = React.useState<boolean>(false);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openRegistration, setOpenRegistration] = React.useState<boolean>(false);
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);
  const apiClient = useApiClient();
  const setNotificationOpen = useSetRecoilState(customerNotificationOpenState);
  const routeUrlFactory = useRouteUrlFactory();
  const handleChange = React.useCallback(
    (fieldName: keyof Values) => (value: string | undefined) => {
      setValues((ps) => ({ ...ps, [fieldName]: value }));
    },
    [],
  );

  const locale = useRecoilValue(localeState);

  const handlePasswordReset = React.useCallback(() => {
    if (values.forgotUser) {
      apiClient
        .post<{ email: string }, { username: string }>('/v1/users/renew-password', {
          username: values.forgotUser,
        })
        .then(
          ({ email }) => {
            showMessage({ message: t('loginPage/messages/passwordSent', { email: email || '' }), variant: 'success' });
            setValues(initialValues);
            setOpen(false);
          },
          () => {
            showMessage({ message: t('loginPage/messages/errorUpdatingPassword'), variant: 'error' });
          },
        );

      // ApiClient
      // .then((res) => {
      //    showMessage({ message: 'Heslo bylo odesláno na Váš e-mail', variant: 'success' });
      //    setValues(initialValues);
      //    setOpen(false);
      // })
      // .catch(() => showMessage({ message: 'Neplatné uživatelské jméno', variant: 'error' }))
      // showMessage({ message: 'Heslo bylo odesláno na Váš e-mail', variant: 'success' });
    }
  }, [values, showMessage, apiClient]);

  const handleLogin = useLoginHandler(
    () => {
      setNotificationOpen(true);
      // const afterLoginPath = location?.search
      //   ? (qs.parse(location?.search, { ignoreQueryPrefix: true })?.from as string)
      //   : routeUrlFactory('customerProfile');
      // TODO: add from?
      const afterLoginPath = routeUrlFactory('catalogue');

      history.replace(afterLoginPath);
    },
    (err) => {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Login Error ', err);
      }
      setError(true);
    },
  );
  const [logoutConfirm, setLogoutConfirm] = React.useState<boolean>(false);
  const t = useTranslate();

  const handleOpenRegistrationDialog = React.useCallback(() => {
    setOpenRegistration(true);
  }, []);

  const handleCloseRegistrationDialog = React.useCallback(() => {
    setOpenRegistration(false);
  }, []);

  // const handleDrawerToggle = React.useCallback((v) => {
  //   setOpen(typeof v === 'boolean' ? v : (prev) => !prev);
  // }, []);

  return (
    <>
      {/* <AppHeader handleDrawerToggle={handleDrawerToggle} /> */}
      <Helmet>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-75F7P7Y36E'></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-75F7P7Y36E');
        `}
        </script>
      </Helmet>
      <Container className={classes.root}>
        <div className={classes.mainWrapper}>
          <div className={classes.sideLoginPanel}>
            <div>
              <div className={classes.backToImg}>
                {locale === 'cs' ? (
                  <Link href='https://stary.magg.cz/'>
                    <SimpleImage src='/Banner-Vstup_na_stare_B2B.jpg' alt='vstup na stare B2B' />
                  </Link>
                ) : (
                  <Link href='https://stary.magg.cz/'>
                    <SimpleImage src='/Banner-Vstup_na_stare_B2B_EN.jpg' alt='entrance to old B2B' />
                  </Link>
                )}
              </div>
            </div>
            {locale === 'cs' ? (
              <div className={classes.infoBox}>
                <h3 className={classes.infoBoxHeading}>Info Box</h3>
                <div className={classes.infoBoxContent}>
                  <div className={classes.infoBoxContentSection}>Vážení obchodní partneři,</div>
                  <div className={classes.infoBoxContentSection}>
                    <span>vítejte na našem novém B2B.</span>
                    <span>V případě technických problémů</span>
                    <span>s registrací a používáním tohoto webu</span>
                    <span>kontaktujte technickou podporu</span>
                  </div>
                  <div className={classes.infoBoxContentSection}>
                    <Link href='mailto:provoz@pht.cz'>provoz@pht.cz</Link>
                    <span>+420 606 711 216</span>
                  </div>

                  <div className={classes.infoBoxContentSection}>
                    <div>E-mailové adresy pro přímé vyřízení:</div>
                    <div>
                      OBJEDNÁVEK = <Link href='mailto:sklad@pht.cz'>sklad@pht.cz</Link>
                    </div>
                    <div>
                      REKLAMACÍ = <Link href='mailto:reklamace@pht.cz'>reklamace@pht.cz</Link>
                    </div>
                    <div>
                      ZASLÁNÍ FAKTUR = <Link href='mailto:fakturace@pht.cz'>fakturace@pht.cz</Link>
                    </div>
                    <div>
                      REGISTRACÍ a OPRAV DAT = <Link href='mailto:provoz@pht.cz'>provoz@pht.cz</Link>
                    </div>
                  </div>
                  <p>
                    Obchodní podmínky zůstávají po dobu paralelního provozu
                    <br />
                    starého a nového B2B beze změny.
                  </p>
                  <p>Vaše PHT a.s.</p>
                </div>
              </div>
            ) : (
              <div className={classes.infoBox}>
                <h3 className={classes.infoBoxHeading}>Info Box</h3>
                <div className={classes.infoBoxContent}>
                  <div className={classes.infoBoxContentSection}>Dear business partners,</div>
                  <div className={classes.infoBoxContentSection}>
                    <span>welcome to our new B2B.</span>
                    <span>In case of any technical problems</span>
                    <span>with registration and using this website,</span>
                    <span>please contact technical support</span>
                  </div>
                  <div className={classes.infoBoxContentSection}>
                    <Link href='mailto:provoz@pht.cz'>provoz@pht.cz</Link>
                    <span>+420 606 711 216</span>
                  </div>

                  <div className={classes.infoBoxContentSection}>
                    <div>Email addresses for direct processing:</div>
                    <div>
                      ORDERS = <Link href='mailto:sklad@pht.cz'>sklad@pht.cz</Link>
                    </div>
                    <div>
                      COMPLAINTS = <Link href='mailto:reklamace@pht.cz'>reklamace@pht.cz</Link>
                    </div>
                    <div>
                      SENDING INVOICES = <Link href='mailto:fakturace@pht.cz'>fakturace@pht.cz</Link>
                    </div>
                    <div>
                      REGISTRATIONS and DATA CORRECTIONS = <Link href='mailto:provoz@pht.cz'>provoz@pht.cz</Link>
                    </div>
                  </div>
                  <p>
                    The terms and conditions remain unchanged during the parallel operation
                    <br />
                    of the old and new B2B.
                  </p>
                  <p>Your PHT a.s.</p>
                </div>
              </div>
            )}
            {!isAuthenticated && (
              <div className={classes.infoBox}>
                <h3 className={classes.infoBoxHeading}>{(t('loginPage/accessToCatalogue') || '').toUpperCase()}</h3>
                <p className={classes.infoBoxContent}>{t('loginPage/accessToCatalogueContent')}</p>
              </div>
            )}
            {!isAuthenticated ? (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  handleLogin(values.username, values.password);
                }}
              >
                <div className={classes.content}>
                  <div className={classes.fields}>
                    <h3>{t('loginPage/twoWordsLogin')}</h3>
                    {error && (
                      <Alert severity='error'>
                        <strong>{t('loginPage/loginError')}</strong> {t('loginPage/wrongCredentials')}
                      </Alert>
                    )}
                    <TextField
                      type='text'
                      label={t('common/username')}
                      onChange={handleChange('username')}
                      value={values.username}
                      fullWidth
                      margin='normal'
                    />
                    <PasswordField
                      label={t('common/password')}
                      onChange={handleChange('password')}
                      value={values.password}
                      fullWidth
                      margin='normal'
                    />
                  </div>
                  <div className={classes.actions}>
                    <Button type='submit' className={classes.loginButton} variant='contained'>
                      {t('loginPage/login')}
                    </Button>
                    <Button
                      onClick={handleOpenRegistrationDialog}
                      component={'a'}
                      className={classes.registrationButton}
                      variant='contained'
                    >
                      {t('loginPage/register')}
                    </Button>
                    <Link component={'a'} onClick={() => setOpen((ps) => !ps)}>
                      {t('loginPage/forgotPassword')}
                    </Link>
                  </div>
                  <Collapse in={open}>
                    <div className={classes.forgotFieldsContainer}>
                      <TextField
                        type='text'
                        label={t('loginPage/enterYourEmail')}
                        onChange={handleChange('forgotUser')}
                        value={values.forgotUser}
                        fullWidth
                        margin='normal'
                      />
                      <Button variant='contained' color='primary' size='small' onClick={handlePasswordReset}>
                        {t('common/send')}
                      </Button>
                    </div>
                  </Collapse>
                </div>
              </form>
            ) : (
              <div>
                <Tooltip title={t('app/logout')}>
                  <IconButton
                    edge='end'
                    color='inherit'
                    onClick={() => {
                      setLogoutConfirm(true);
                    }}
                  >
                    <LockIcon />
                  </IconButton>
                </Tooltip>
                <DialogConfirm
                  open={logoutConfirm}
                  onClose={() => {
                    setLogoutConfirm(false);
                  }}
                  onConfirm={() => {
                    history.push(routeUrlFactory('logout'));
                  }}
                  title={t('app/logout')}
                >
                  <div>{t('app/logoutConfirmation')}</div>
                </DialogConfirm>
              </div>
            )}
          </div>

          <div className={classes.hpContent}>
            <div className={classes.carouselWrapper}>
              <Carousel
                className={classes.carousel}
                autoPlay
                infiniteLoop
                interval={5000}
                showStatus={false}
                showThumbs={false}
              >
                {carouselItems.map((item, index) => {
                  if (item.hrefProps) {
                    return (
                      <Link key={index} href={routeUrlFactory(item.hrefProps.type, item.hrefProps.options)}>
                        <SimpleImage src={item.src[locale]} title={item.title[locale]} alt={item.alt[locale]} />
                      </Link>
                    );
                  }
                  return (
                    <SimpleImage key={index} src={item.src[locale]} title={item.title[locale]} alt={item.alt[locale]} />
                  );
                })}
              </Carousel>
            </div>
            {locale === 'cs' ? (
              <div className={classes.imgSection}>
                <Link href={routeUrlFactory('categories', { params: { categories: 'akce' } })}>
                  <SimpleImage
                    src='/Banner_Akce_kveten.jpg'
                    alt='Info banner - akce květen'
                    title='Info banner - akce květen'
                  />
                </Link>
                <Link href={routeUrlFactory('categories', { params: { categories: 'vyprodej' } })}>
                  <SimpleImage
                    src='/Banner-vyprodej.jpg'
                    alt='Info banner - výprodej skladových zásob'
                    title='Info banner - výprodej skladových zásob'
                  />
                </Link>
              </div>
            ) : (
              <div className={classes.imgSection}>
                <Link href={routeUrlFactory('categories', { params: { categories: 'akce' } })}>
                  <SimpleImage
                    src='/Banner_Akce_kveten_EN.jpg'
                    alt='Info banner - May actions'
                    title='Info banner - May actions'
                  />
                </Link>
                <Link href={routeUrlFactory('categories', { params: { categories: 'vyprodej' } })}>
                  <SimpleImage
                    src='/Banner-vyprodej_EN.jpg'
                    alt='Info banner - warehouse stock sale'
                    title='Info banner - warehouse stock sale'
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
      </Container>
      <LoginRegistrationDialog openDialog={openRegistration} onClose={handleCloseRegistrationDialog} />
    </>
  );
};

HomePageNew.displayName = 'HomePageNew';

export default wrapLayout(HomePageNew, InfoLayout);
