import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { apiClientState } from '../../../api/appState';
import { SidiaryUser, SubsidiaryUser } from './useSubsidiaryUsersList';

export interface SubsidiaryUserCreateParams extends SubsidiaryUserCreateFormValues {
  subsidiary: string;
  username: string | undefined;
}

export interface SidiaryUserCreateParams extends SidiaryUserCreateFormValues {
  password: string | undefined;
  sidiary: string;
  username: string | undefined;
}

export interface SubsidiaryUserCreateFormValues {
  currency?: string | undefined;
  // confirmPassword: string | undefined;
  deliveryPlaces: Array<string>;
  email: string | undefined;
  name: string | undefined;
  orderTotalLimit: number | undefined;
  supervisor: string | undefined;
  // password: string | undefined;
  // username: string | undefined;
}

export interface SubsidiaryUserCreateFormErrors {
  // confirmPassword?: string;
  email?: string;
  name?: string;
  orderTotalLimit?: string;
  supervisor?: string;
  // password?: string;
  // username?: string;
}

export interface SidiaryUserCreateFormTouched {
  confirmPassword?: boolean;
  email?: boolean;
  name?: boolean;
  orderTotalLimit?: number | undefined;
  password?: boolean;
  supervisor?: string | undefined;
  // username?: boolean;
}

export interface SidiaryUserCreateFormValues {
  confirmPassword: string | undefined;
  email: string | undefined;
  name: string | undefined;
  orderTotalLimit: number | undefined;
  password: string | undefined;
  supervisor: string | undefined;
  // username: string | undefined;
}

export interface SidiaryUserCreateFormErrors {
  confirmPassword?: string;
  email?: string;
  name?: string;
  orderTotalLimit?: string;
  password?: string;
  supervisor?: string;
  // username?: string;
}

export interface SubsidiaryUserCreateFormTouched {
  // confirmPassword?: boolean;
  email?: boolean;
  name?: boolean;
  orderTotalLimit?: boolean;
  supervisor?: boolean;
  // password?: boolean;
  // username?: boolean;
}

export function useSubsidiaryUserCreateHandler(): (params: SubsidiaryUserCreateParams) => Promise<SubsidiaryUser> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    (params: SubsidiaryUserCreateParams) =>
      apiClient.post<SubsidiaryUser, SubsidiaryUserCreateParams>('/v1/users/create-for-subsidiary', params),
    [apiClient],
  );
}

export function useSidiaryUserCreateHandler(): (params: SidiaryUserCreateParams) => Promise<SidiaryUser> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    (params: SidiaryUserCreateParams) =>
      apiClient.post<SidiaryUser, SidiaryUserCreateParams>('/v1/users/create-for-sidiary', params),
    [apiClient],
  );
}
