import React, { useState } from 'react';
import TextField from '@sprinx/react-mui-fields/TextField';
import { useTranslate } from '@sprinx/react-globalize';
import SelectField from '@sprinx/react-mui-fields/SelectField';
import SelectFieldItem from '@sprinx/react-mui-fields/SelectFieldItem';
import Typography from '@material-ui/core/Typography';
import WrapDialogForm from '@sprinx/react-mui-components/WrapDialogForm';
import FormikExtended from '@sprinx/react-forms/FormikExtended';
import { useRecoilValue } from 'recoil';
import { apiClientState } from '../../../api/appState';

export type CustomerProfilePageNewBranchPopupProps = {
  onClose: () => void;
  open: boolean;
};

const countries = ['CZ', 'SK', 'DE', 'SR', 'HR', 'SL', 'HU'];

type NewBranchFormValues = {
  city: string;
  country: string;
  email: string;
  name: string;
  phone: string;
  street: string;
  zip: string;
};

// NOTE: Unfortunatelly, zod + zodi18 are not available in this project, due to babel issues
export const CustomerProfilePageNewBranchPopup = ({ onClose, open }: CustomerProfilePageNewBranchPopupProps) => {
  const t = useTranslate();
  const apiClient = useRecoilValue(apiClientState);
  const [contentType, setContentType] = useState<'form' | 'success' | 'error'>('form');

  const handleSave = async (data: NewBranchFormValues, { resetForm }: any) => {
    try {
      await apiClient.post('/v1/branches/request-new', data);
      setContentType('success');
      resetForm();
    } catch (err) {
      setContentType('error');
      console.error(err);
    }
  };

  const handleClose = async () => {
    onClose();
    setContentType('form');
  };

  return (
    <FormikExtended
      initialValues={{
        street: '',
        city: '',
        country: '',
        zip: '',
        email: '',
        name: '',
        phone: '',
      }}
      onSubmit={contentType === 'form' ? handleSave : handleClose}
      validationFastest
      validationSchema={{
        street: { type: 'string', empty: false },
        city: { type: 'string', empty: false },
        country: { type: 'string', empty: false },
        zip: { type: 'string', empty: false },
        name: { type: 'string', empty: false },
        email: { type: 'email', empty: false },
        phone: { type: 'string', empty: false },
      }}
      render={(formikProps: any) => (
        <WrapDialogForm
          {...formikProps}
          open={open}
          onClose={handleClose}
          dialogProps={{
            buttonSaveLabel: t('common/send'),
            buttonCancelLabel: t('loginPageRegistration/close'),
            fullScreen: true,
            maxWidth: 'md',
          }}
          render={() => {
            // I am sorry for this

            if (contentType === 'success') {
              return (
                <Typography variant='subtitle1' component='div'>
                  {t('customerProfilePage/newBranch/success')}
                </Typography>
              );
            }

            if (contentType === 'error') {
              return <Typography>{t('customerProfilePage/newBranch/error')}</Typography>;
            }

            const { errors, onChange: handleChange, values } = formikProps;

            return (
              <>
                <Typography variant='subtitle1' component='div' style={{ marginBottom: 4 }}>
                  {t('customerProfilePage/newBranch/popupTitle')}
                </Typography>
                <TextField
                  type='text'
                  label={t('common/name')}
                  onChange={handleChange('name')}
                  value={values.name}
                  error={errors.name}
                  fullWidth
                  margin='normal'
                />

                <TextField
                  type='text'
                  label={t('common/street')}
                  onChange={handleChange('street')}
                  value={values.street}
                  error={errors.street}
                  fullWidth
                  margin='normal'
                />

                <TextField
                  type='text'
                  label={t('common/city')}
                  onChange={handleChange('city')}
                  value={values.city}
                  error={errors.city}
                  fullWidth
                  margin='normal'
                />

                <TextField
                  type='text'
                  label={t('common/zip')}
                  onChange={handleChange('zip')}
                  value={values.zip}
                  error={errors.zip}
                  fullWidth
                  margin='normal'
                />

                <SelectField
                  label={t('common/country')}
                  onChange={handleChange('country')}
                  value={values.country}
                  error={errors.country}
                  fullWidth
                  margin='normal'
                >
                  {countries.map((country) => (
                    <SelectFieldItem key={country} value={country}>
                      {country}
                    </SelectFieldItem>
                  ))}
                </SelectField>

                <TextField
                  type='text'
                  label={t('common/phone')}
                  onChange={handleChange('phone')}
                  value={values.phone}
                  error={errors.phone}
                  fullWidth
                  margin='normal'
                />

                <TextField
                  type='text'
                  label={t('common/email')}
                  onChange={handleChange('email')}
                  value={values.email}
                  error={errors.email}
                  fullWidth
                  margin='normal'
                />
              </>
            );
          }}
        />
      )}
    />
  );
};
