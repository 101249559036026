import Typography from '@material-ui/core/Typography';
import FormikExtended, { FormikExtendedForwardProps } from '@sprinx/react-forms/FormikExtended';
import WrapDialogForm from '@sprinx/react-mui-components/WrapDialogForm';
import PasswordField from '@sprinx/react-mui-fields/PasswordField';
import PriceField from '@sprinx/react-mui-fields/PriceField';
import TextField from '@sprinx/react-mui-fields/TextField';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslate } from '@sprinx/react-globalize';
import { currencyState } from '../../../api/appState';
import usePasswordStrongEnough from '../../../components/UserSetPasswordDialog/usePasswordStrongEnough';
import CustomerProfilePageUserSupervisorSelector from './CustomerProfilePageUserSupervisorSelector';
import {
  SidiaryUserCreateFormErrors,
  SidiaryUserCreateFormTouched,
  SidiaryUserCreateFormValues,
} from './useSubsidiaryUsersCreateHandler';

export interface CustomerProfilePageCreateSubsidiaryUserDialogProps
  extends Partial<
    FormikExtendedForwardProps<SidiaryUserCreateFormValues, SidiaryUserCreateFormErrors, SidiaryUserCreateFormTouched>
  > {
  onClose: () => void;
  onFormReleased: (p: any) => any;
  onSave: (createUserData: SidiaryUserCreateFormValues) => Promise<void>;
  open?: boolean;
  sidiary: {
    id: string;
    name: string;
  };
}

const CustomerProfilePageCreateSubsidiaryUserDialog: React.FC<CustomerProfilePageCreateSubsidiaryUserDialogProps> = ({
  sidiary,
  open,
  onClose,
  onFormReleased,
  ...rest
}) => {
  const passwordStrongEnough = usePasswordStrongEnough();
  const currency = useRecoilValue(currencyState);
  const t = useTranslate();

  return open ? (
    <FormikExtended<SidiaryUserCreateFormValues, SidiaryUserCreateFormErrors, SidiaryUserCreateFormTouched>
      {...rest}
      initialValues={{
        // username: subsidiaryIdent,
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        supervisor: undefined,
        orderTotalLimit: undefined,
      }}
      validationFastest={{
        messages: {
          confirmPassword: 'customerProfilePage/changePassword/confirmPassword',
          passwordStrongEnough: 'customerProfilePage/changePassword/passwordStrongEnough',
        },
      }}
      validationSchema={{
        name: { type: 'string', empty: false },
        email: { type: 'email' },
        password: {
          type: 'custom',
          check: function myCheckPassword(value: string): boolean | string[] {
            if (!passwordStrongEnough(value)) {
              // eslint-disable-next-line react/no-this-in-sfc
              // @ts-ignore
              return this.makeError('passwordStrongEnough');
            }
            return true;
          },
        },
        confirmPassword: {
          type: 'custom',
          check: function myCheck(
            value: string,
            schema: any,
            field: any,
            parent: SidiaryUserCreateFormValues,
          ): boolean | string[] {
            if (value !== parent.password) {
              // eslint-disable-next-line react/no-this-in-sfc
              // @ts-ignore
              return this.makeError('confirmPassword');
            }
            return true;
          },
        },
        orderTotalLimit: { type: 'number', optional: true },
        supervisor: { type: 'string', optional: true },
      }}
    >
      {(formikProps) => (
        <WrapDialogForm
          {...formikProps}
          dialogTitle={t('customerProfilePage/createMasterUser')}
          open={open}
          onClose={onClose}
          dialogProps={{
            // buttonSaveLabel: '',
            fullScreen: true,
            maxWidth: 'md',
          }}
          render={() => {
            const { errors, onChange: handleChange, values } = formikProps;
            return (
              <>
                <Typography variant='subtitle1' component='div' style={{ marginBottom: 16 }}>
                  {sidiary.name}
                </Typography>
                {/* <TextFieldPreview label={'Uživatelské jméno'} value={values.username} fullWidth /> */}
                <TextField
                  label={t('customerProfilePage/fullName')}
                  helperText={''}
                  value={values.name}
                  error={errors.name}
                  onChange={handleChange('name')}
                  fullWidth
                />
                <TextField
                  label={t('customerProfilePage/contactEmail')}
                  helperText={''}
                  value={values.email}
                  error={errors.email}
                  onChange={handleChange('email')}
                  type='email'
                  fullWidth
                />
                <PasswordField
                  label={t('common/password')}
                  helperText={''}
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange('password')}
                  fullWidth
                />
                <PasswordField
                  label={t('common/passwordConfirm')}
                  helperText={''}
                  value={values.confirmPassword}
                  error={errors.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  fullWidth
                />
                <PriceField
                  currency={currency}
                  label={t('orders/order/priceLimit')}
                  helperText={''}
                  value={values.orderTotalLimit}
                  error={errors.orderTotalLimit}
                  onChange={handleChange('orderTotalLimit')}
                  fullWidth
                />
                <CustomerProfilePageUserSupervisorSelector
                  fullWidth
                  label={t('common/approver')}
                  customerId={sidiary.id}
                  userId={'notYetCreated'}
                  value={values.supervisor}
                  onChange={handleChange('supervisor')}
                />
              </>
            );
          }}
        />
      )}
    </FormikExtended>
  ) : null;
};

CustomerProfilePageCreateSubsidiaryUserDialog.displayName = 'CustomerProfilePageCreateSubsidiaryUserDialog';

export default CustomerProfilePageCreateSubsidiaryUserDialog;
