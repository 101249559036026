import Typography from '@material-ui/core/Typography';
import FormikExtended, { FormikExtendedForwardProps } from '@sprinx/react-forms/FormikExtended';
import WrapDialogForm from '@sprinx/react-mui-components/WrapDialogForm';
import PasswordField from '@sprinx/react-mui-fields/PasswordField';
import React from 'react';
import { useTranslate } from '@sprinx/react-globalize';
import usePasswordStrongEnough from './usePasswordStrongEnough';
import useUserSetPasswordHandler, {
  UserSetPasswordFormErrors,
  UserSetPasswordFormTouched,
  UserSetPasswordFormValues,
} from './useUserSetPasswordHandler';

export interface UserSetPasswordDialogProps
  extends Partial<
    FormikExtendedForwardProps<UserSetPasswordFormValues, UserSetPasswordFormErrors, UserSetPasswordFormTouched>
  > {
  onClose: () => void;
  onFormReleased: (p: any) => any;
  // onSave: (setPasswordData: UserSetPasswordFormValues) => Promise<void>;
  open?: boolean;
  subtitle?: string;
  userId?: string;
}

const UserSetPasswordDialog: React.FC<UserSetPasswordDialogProps> = ({
  open,
  subtitle,
  onClose,
  onFormReleased,
  // onSave,
  userId,
  ...rest
}) => {
  const passwordStrongEnough = usePasswordStrongEnough();
  const setPassword = useUserSetPasswordHandler();

  const handleSave = React.useCallback(
    async ({ password }: UserSetPasswordFormValues) => {
      if (userId && password) {
        setPassword({ password, userId });
      }
    },
    [setPassword, userId],
  );

  const t = useTranslate();

  return open ? (
    <FormikExtended<UserSetPasswordFormValues, UserSetPasswordFormErrors, UserSetPasswordFormTouched>
      {...rest}
      onFormReleased={onFormReleased}
      onSave={handleSave}
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationFastest={{
        messages: {
          confirmPassword: 'customerProfilePage/changePassword/confirmPassword',
          passwordStrongEnough: 'customerProfilePage/changePassword/passwordStrongEnough',
        },
      }}
      validationSchema={{
        password: {
          type: 'custom',
          check: function myCheckPassword(value: string): boolean | string[] {
            if (!passwordStrongEnough(value)) {
              // eslint-disable-next-line react/no-this-in-sfc
              // @ts-ignore
              return this.makeError('passwordStrongEnough');
            }
            return true;
          },
        },
        confirmPassword: {
          type: 'custom',
          check: function myCheck(
            value: string,
            schema: any,
            field: any,
            parent: UserSetPasswordFormValues,
          ): boolean | string[] {
            if (value !== parent.password) {
              // eslint-disable-next-line react/no-this-in-sfc
              // @ts-ignore
              return this.makeError('confirmPassword');
            }
            return true;
          },
        },
      }}
    >
      {(formikProps) => (
        <WrapDialogForm
          {...formikProps}
          dialogTitle={t('customerProfilePage/changePassword/label')}
          open={open}
          onClose={onClose}
          // variant='flat-no-gutters'
          dialogProps={{
            // buttonSaveLabel: '',
            fullWidth: true,
            maxWidth: 'sm',
          }}
          render={() => {
            const { errors, onChange: handleChange, values } = formikProps;
            return (
              <>
                <Typography variant='subtitle1' component='div'>
                  {subtitle}
                </Typography>
                <PasswordField
                  label={t('common/password')}
                  helperText={''}
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange('password')}
                  fullWidth
                />
                <PasswordField
                  label={t('common/passwordConfirm')}
                  helperText={''}
                  value={values.confirmPassword}
                  error={errors.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  fullWidth
                />
              </>
            );
          }}
        />
      )}
    </FormikExtended>
  ) : null;
};

UserSetPasswordDialog.displayName = 'UserSetPasswordDialog';

export default UserSetPasswordDialog;
