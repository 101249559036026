import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslate } from '@sprinx/react-globalize';
import { /*authState,*/ authUserState } from '../../../api/appState';
import UserSetPasswordDialog from '../../../components/UserSetPasswordDialog';
import CustomerProfilePageContactCard from './CustomerProfilePageContactCard';

export type CustomerProfilePageUserPreviewProps = StandardDivProps<CustomerProfilePageUserPreviewClassKey>;

export type CustomerProfilePageUserPreviewClassKey = 'root' | 'title' | 'button';

const themeSettings = { name: 'CustomerProfilePageUserPreview' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CustomerProfilePageUserPreviewClassKey, {}>({
      root: { display: 'flex', flexDirection: 'column', flexGrow: 1 },
      title: {
        ...theme.typography.h6,
        fontWeight: 600,
      },
      button: {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(1),
      },
    }),
  themeSettings,
);

const CustomerProfilePageUserPreview = React.forwardRef<HTMLDivElement, CustomerProfilePageUserPreviewProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const user = useRecoilValue(authUserState);
    // const auth = useRecoilValue(authState);
    // const customerId = auth?.profile?.customer?.id;
    const t = useTranslate();
    const [passwordDialogOpen, setPasswordDialogOpen] = React.useState(false);
    const handlePasswordDialogClose = React.useCallback(() => setPasswordDialogOpen(false), [setPasswordDialogOpen]);
    const handlePasswordDialogOpen = React.useCallback(() => setPasswordDialogOpen(true), [setPasswordDialogOpen]);
    if (!user) {
      return <div>{t('customerProfilePage/loadingError')}</div>;
    }

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <CustomerProfilePageContactCard title={t('customerProfilePage/user')}>
          <span className={classes.title}>{user.name}</span>
          <span>e-mail: {user.email}</span>
          <Button className={classes.button} onClick={handlePasswordDialogOpen} size='small' color='secondary'>
            {t('customerProfilePage/changePassword/label')}
          </Button>
          <UserSetPasswordDialog
            open={passwordDialogOpen}
            // onSave={handleSave}
            onFormReleased={handlePasswordDialogClose}
            onClose={handlePasswordDialogClose}
            subtitle={user.name}
            userId={user.id}
          />
        </CustomerProfilePageContactCard>
      </div>
    );
  },
);

CustomerProfilePageUserPreview.displayName = 'CustomerProfilePageUserPreview';

export default withThemeProps(themeSettings)(CustomerProfilePageUserPreview);
